<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    style="margin: auto"
  >
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-5 text-xl-h4 text-center">
      Sugerencias
    </div>

    <div class="text-center">
      <v-text-field
        outlined=""
        v-model="asunto"
        :rules="asuntoRules"
        label="Asunto"
        required
        style="max-width: 1000px; margin-left:auto; margin-right:auto"
        dense
      ></v-text-field>

      <v-text-field
        outlined=""
        v-model="name"
        :rules="nameRules"
        label="Nombre"
        required
        style="max-width: 1000px; margin-left:auto; margin-right:auto"
        dense
      ></v-text-field>

      <v-textarea
        outlined=""
        v-model="sugerencia"
        :rules="sugerenciaRules"
        label="Sugerencia"
        name="input-7-4"
        auto-grow
        value=""
        style="max-width: 1000px; margin-left:auto; margin-right:auto"
        dense
      ></v-textarea>

        <v-btn
        :loading="loading"
        color="primary"
        class="mr-4"
        @click="validate"
        style="width:150px; margin-left:auto; margin-right:auto"
        >
        Enviar
        </v-btn>
    </div>
  </v-form>
</template>

<script>
  import axios from "axios"
  import { mapState } from "vuex";
  import router from '../router';
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data: () => ({
      valid: false,
      loading: false,
      asunto: '',
      asuntoRules: [
        v => !!v || 'El asunto es obligatorio',
      ],
      name: '',
      nameRules: [
        v => !!v || 'El nombre es obligatorio',
      ],
      sugerencia: '',
      sugerenciaRules: [
          v => !!v || 'La sugerencia es obligatoria',
      ],
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
        if (this.valid)
        {
            this.loading = true;
            this.enviar();
        }
      },
      enviar () {
        var sugerenciaObj = { MsjCat1Id: 1, MsjNom: this.asunto, MsjTxt: this.sugerencia, UsrOriNom: this.name, EmpId: this.empId };
        // var sugerenciaJson = JSON.stringify(sugerenciaObj);

          var controllerParameters = {
                    Action: "SAVE_MENSAJE",
                    Msj: sugerenciaObj,
                    Desarrollo: this.$store.state.desarrollo
          }

        var AuthToken = localStorage.getItem('token');
        // axios({ method: "PUT", "url": this.urlRaiz + "/api/mensajes", "data": sugerenciaJson, "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
            .then(result =>{
              if (result.status >= 200 && result.status <= 299)
              {
                this.loading = false;
                alert("Sugerencia enviada con éxito");      
                router.push('/');
              }
            })
            .catch(error => {
                    this.loading = false;
                    alert(error);
        })
      }
    },
  }
</script>